import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import {useDispatch} from "react-redux";
import Fade from "@mui/material/Fade";
import MDBox from "../MDBox";
import Grid from "@mui/material/Grid";
import MDTypography from "../MDTypography";
import MDInput from "../MDInput";
import MDButton from "../MDButton";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import {useState} from "react";
import {errorToast, infoToast} from "../../utils/toastify";
import Modal from "@mui/material/Modal";
import {useUpdatePasswordClientMutation} from "../../Redux/slices/userApiSlice";


export default function UpdatePassword({userId}) {

    const [updatePasswordClient, {isLoading}] = useUpdatePasswordClientMutation()
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch()
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        borderRadius: '14px',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };
    // alert(userId)
    const [password, setPassword] = useState('')
    const submitHandler = async (e) => {

        e.preventDefault();
        try {

            const res = await updatePasswordClient({password, id: userId}).unwrap();
            infoToast(`Success`)
            setOpen(false)
            setPassword('')
        } catch (err) {
            errorToast(err.error);
        }
    };
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <React.Fragment>
            <Tooltip title={'Edit'}>
                <MDButton onClick={handleClickOpen} color={'warning'} style={{marginRight: 15}} variant={'contained'}><Icon>edit</Icon></MDButton>
            </Tooltip>
            <Modal
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition

            >
                <Fade in={open}>
                    <MDBox sx={style}>
                        <form onSubmit={submitHandler}>
                            <Grid mb={5} container>

                                <Grid container direction="row">
                                    <Grid item xs={8}>
                                        <MDTypography variant="h5">
                                            Change password
                                        </MDTypography>
                                    </Grid>

                                </Grid>
                                <Grid
                                    container
                                    direction="row"

                                    spacing={3}
                                >

                                    <Grid item mt={3} xs={12}>
                                        <MDInput
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            fullWidth
                                            margin="dense"
                                            type={'text'}
                                            variant="outlined"
                                            label="password"
                                            id="password"
                                            required
                                        />
                                    </Grid>

                                </Grid>


                            </Grid>
                            <MDButton type={'submit'} color={'info'} style={{marginTop: 4}}>{isLoading ? "Loading..." : "Submit"}</MDButton>

                        </form>
                    </MDBox>
                </Fade>
            </Modal>
        </React.Fragment>
    );
}
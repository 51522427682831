/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import {useGetOnlineQuery, useSaleInfoQuery} from "../../Redux/slices/userApiSlice";
import {useGetPostsQuery} from "../../Redux/slices/postApiSlice";

function Dashboard() {
  const { sales, tasks } = reportsLineChartData;
    const {data: onlines, isLoading} = useGetOnlineQuery()
    const {data: posts} = useGetPostsQuery({})
  const {data: sale, isLoading: isLoading2} = useSaleInfoQuery()


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        {isLoading  || isLoading2 ? null : (
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                      color="dark"
                      icon="currency_exchange"
                      title="Sales"
                      count={sale ? sale.data.count : 0}

                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                      icon="crisis_alert"
                      title="Posts"
                      count={posts ? posts.data.amount : 0}

                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                      color="success"
                      icon="online_prediction"
                      title="Online Users"
                      count={onlines ? onlines.data.length : 0}

                  />
                </MDBox>
              </Grid>

            </Grid>
        )}

      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;

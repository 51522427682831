import { createSlice } from '@reduxjs/toolkit';
import {io} from "socket.io-client";
const socket = io(process.env.REACT_APP_BASE_URL, {withCredentials: false});

const initialState = {
    userInfo: localStorage.getItem('userInfo')
        ? JSON.parse(localStorage.getItem('userInfo'))
        : {},
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setCredentials: (state, action) => {
            state.userInfo = action.payload;
            localStorage.setItem('userInfo', JSON.stringify(action.payload));
        },
        logout: (state, action) => {
            if (state.userInfo){
                socket.emit("USER_OFFLINE", state.userInfo?.user?.id)
                state.userInfo = null;


                localStorage.clear();
                window.location.href = 'login'
            }
        },
    },
});

export const { setCredentials, logout } = authSlice.actions;


export default authSlice.reducer;
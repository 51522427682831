
import { apiSlice } from './apiSlice';
import {io} from "socket.io-client";
import {infoToast} from "../../utils/toastify";
const socket = io(process.env.REACT_APP_BASE_URL, {withCredentials: false});

export const buyedApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getBuyedPosts: builder.query({
            query: () => ({
                url: `/buyedPost/`,
            }),
            async onCacheEntryAdded(
                arg,

                {  updateCachedData, cacheDataLoaded, cacheEntryRemoved },
            ) {
                try {
                    await cacheDataLoaded;
                    // the /chat-messages endpoint responded already


                    socket.on("NEW_BUY", (data) => {
                        infoToast(`New Buy!`)
                        updateCachedData((draft) => {
                            draft.data = JSON.parse(JSON.stringify(data))
                        });
                    });


                    await cacheEntryRemoved;
                    socket.off('connect');

                } catch {

                }
            },
            keepUnusedDataFor: 5,
            providesTags: ['Buyed'],
        }),
        sendBuy: builder.mutation({
            query: (data) => {
                socket.emit("SEND_BUY", data)
            },
            invalidatesTags: ['Buyed'],
        }),




    }),
});

export const {
    useGetBuyedPostsQuery,
    useSendBuyMutation,

} = buyedApiSlice;
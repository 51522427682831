import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@mui/material/Divider';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Fab from '@material-ui/core/Fab';
import SendIcon from '@material-ui/icons/Send';
import MDInput from "../../../../components/MDInput";
import MDBox from "../../../../components/MDBox";
import MDBadge from "../../../../components/MDBadge";
import MDTypography from "../../../../components/MDTypography";
import team2 from "../../../../assets/images/team-2.jpg";
import {Author} from "../../../tables/data/authorsTableData";
import MDButton from "../../../../components/MDButton";
import {navbar} from "../../../../examples/Navbars/DashboardNavbar/styles";
import Card from "@mui/material/Card";
import Contact from "../contact";
import ContactList from "../contactList";
import MessageList from "../messageList";
import ChatInput from "../chatInput";
import ChatHeader from "../chatHeader";
import {useSelector} from "react-redux";
import {useJoinChatMutation} from "../../../../Redux/slices/messageApiSlice";
import {useGetChatsByIdQuery} from "../../../../Redux/slices/chatApiSlice";

const useStyles = makeStyles({

    table: {
        minWidth: 650,
    },
    chatSection: {
        width: '100%',
    },


    messageArea: {
        height: '70vh',
        overflowY: 'auto'
    }
});

const Chat = () => {
    const classes = useStyles();
    const chat = useSelector(state => state.chats.chatInfo)
    const [joinChat] = useJoinChatMutation()
    const user = useSelector(state => state.auth.userInfo.user)
    const {data: chats, isLoading} = useGetChatsByIdQuery(user.id)
    console.log(chat)
    useEffect(() => {
        if (chats){
            const arr = chats.data.map(item => {
                console.log(item)
                const res = joinChat(item.id)
            })
        }
    }, [chats])
    return (

                <Grid container   component={Paper} className={classes.chatSection}>
                    <Grid item xs={12}>
                        {chat ? (
                            <>
                                <ChatHeader/>
                            </>
                        ): null}
                    </Grid>
                    <Grid
                                item xs={3} className={classes.borderRight500}>



                        <ContactList style={{minHeight: '700px'}}/>
                    </Grid>
                    <Grid style={{height: '80hv'}} item xs={9}>
                        {chat ? (
                            <>
                                <MessageList/>
                                <Divider />

                                <ChatInput/>
                            </>
                        ): (
                            <MDBox style={{marginTop: '30%', marginLeft: '40%'}}>
                                Select a chat
                            </MDBox>
                        )}

                    </Grid>
                </Grid>

    );
}

export default Chat;
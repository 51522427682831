import React, {useState} from 'react';
import Grid from "@mui/material/Grid";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import SendIcon from "@material-ui/icons/Send";
import MDBox from "../../../components/MDBox";
import {useSendMessageMutation} from "../../../Redux/slices/messageApiSlice";
import {useSelector} from "react-redux";
import {errorToast} from "../../../utils/toastify";

const ChatInput = () => {
    const [sendMessage] = useSendMessageMutation()
    const [message, setMessage] = useState('')
    const user = useSelector(state => state.auth.userInfo.user)
    const chat = useSelector(state => state.chats.chatInfo)
    const [has, setHas] = useState(false)
    const onMessage = (e) => {
        let re = /["'<>+=~|№#$%^&*]/;
        if(re.test(e.target.value)){
            setHas(true)
        } else {
            setMessage(e.target.value)
        }
    }
    const onSend =  async (e) => {
        e.preventDefault()
            const res = await sendMessage({receiver: chat.id, sender: user.id, message, title: chat.title})
            setMessage('')

    }
    return (
            <MDBox component={'form'} onSubmit={onSend}>
                <Grid  container style={{marginBottom: '20px'}} >

                    {user.isBlocked ? null : (
                        <>
                            <Grid item xs={10}>
                                <MDInput
                                    value={message}
                                    onChange={onMessage}
                                    required
                                    id="outlined-basic-email"
                                    label="Type Something"
                                    fullWidth />
                            </Grid>
                            <Grid xs={1} align="right">
                                <MDButton type={"submit"} color="info" aria-label="add"><SendIcon /></MDButton>
                            </Grid>
                        </>
                    )}

                </Grid>
            </MDBox>
    );
};

export default ChatInput;
import React, { useEffect, useState } from "react";
import { Treebeard } from "react-treebeard";
import decorators from "react-treebeard/lib/components/decorators";
import defaultTheme from "react-treebeard/lib/themes/default";
import MDTypography from "../../components/MDTypography";
import style from "./TreeStyle";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";
import {FormControlLabel, Popover, Radio} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import {setCart} from "../../Redux/slices/cartSlice";
import {useDispatch, useSelector} from "react-redux";
import MDButton from "../../components/MDButton";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import MDBox from "../../components/MDBox";

// Кастомный заголовок для отображения узлов

const CustomHeader = ({ node, style }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div style={style.base}>
            <div style={{ ...style.title, display: "flex", color: 'black' }}>
                    <Grid container>
                        <Grid item xs={11}>
                            {node.price ? <Icon>insert_drive_file</Icon> : <Icon>folder</Icon> } {`${node.name}`}

                        </Grid>
                        <Grid item xs={1}>
                            {node.price ? (
                                <MDTypography style={{color: 'black'}}>
                                    <Tooltip title={'More Info'}>
                                        <MDButton style={{marginLeft: 20}} aria-describedby={id} variant="contained" color={'info'} onClick={handleClick}>
                                            <Icon>info</Icon>
                                        </MDButton>
                                    </Tooltip>
                                    <Popover
                                        style={{backgroundColor: 'white'}}
                                        id={id}
                                        open={open}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        style={{opacity: 1}}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <MDTypography sx={{ p: 1 }}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Icon>article</Icon>Filename:<MDTypography>{node.name}</MDTypography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Icon>sell</Icon>Price:<MDTypography>{node.price}$</MDTypography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Icon>access_time</Icon>Creation date:<MDTypography>{node.date}</MDTypography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Icon>cloud_download</Icon>Weight:<MDTypography>{node.weight} KB</MDTypography>

                                                </Grid>
                                            </Grid>

                                        </MDTypography>
                                    </Popover>
                                </MDTypography>
                            ) : null}

                        </Grid>
                    </Grid>
                <Divider/>
            </div>
        </div>
    );
};

// Кастомный контейнер для отображения узлов
const CustomContainer = ({ style, decorators, terminal, onClick, node }) => {
    const [checked, setChecked] = React.useState(true);
    const dispatch = useDispatch()
    const cart = useSelector(state => state.cart.cart)
    const handleChange = (event) => {
        setChecked(event.target.checked);
        if (event.target.checked){
            dispatch(setCart({totalPrice: cart.totalPrice + node.price, files: [...cart.files, node.path]}))
        } else {
            const finalFiles = cart.files.filter(item => item.path !== node.path);
            dispatch(setCart({totalPrice: cart.totalPrice - node.price, files: finalFiles}))
        }
        console.log(cart)

    };
    return (
        <div onClick={onClick} style={style.container[0]}>
            <decorators.Header node={node} style={style.header} />
            <span >
                {node.price ?                     <FormControlLabel label={`Add to cart for ${node.price} $`} control={<Checkbox value={checked} onChange={handleChange} />} value={node.path} />
                : null}
            </span>
            <Divider/>
        </div>
    );
};

// Оборачиваем кастомные декораторы в функциональный компонент
const CustomDecorators = {
    Header: CustomHeader,
    Container: CustomContainer,
};

// Основной компонент
const TreeView = ({ dataa }) => {
    const [currentPath, setCurrentPath] = useState([]); // Хранит текущее имя узла
    const [treeData, setTreeData] = useState(dataa); // Изначальные данные дерева
    const [fullData, setFullData] = useState(dataa); // Хранит полные данные дерева для возврата

    // Обработчик для переключения состояния узлов
    const handleToggle = (node) => {
        if (node.children && node.children.length > 0) {
            setCurrentPath((prev) => [...prev, node.name]);
            setTreeData(node.children); // используем только дочерние элементы
        }
    };

    // Функция для возврата на уровень вверх
    const goBack = () => {

        const newPath = currentPath.slice(0, -1); // Убираем последний элемент из пути
        setTreeData(fullData); // Возвращаем к полным данным

        // Обновляем текущее состояние для выбора нужных детей
        newPath.forEach((name) => {
            console.log(fullData)
            const nextNode = fullData.children.find(child => child.name === name);
            if (nextNode && nextNode.children) {
                setTreeData(nextNode.children); // Переходим к следующему уровню
            }
        });

        setCurrentPath(newPath);
    };

    // Функция для перехода на родительский узел по клику
    const handleParentClick = (name) => {
        const newPath = [...currentPath.slice(0, currentPath.length - 1), name]; // Обновляем путь
        const parentNode = fullData.children.find(child => child.name === name); // Находим узел родителя

        if (parentNode && parentNode.children) {
            setTreeData(parentNode.children); // Устанавливаем дочерние узлы для отображения
        }

        setCurrentPath(newPath); // Обновляем текущее состояние пути
    };

    return (
        <>
            <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                color={'white'}

            >
                <MDBox bgColor={'white'} color={'black'}
                       borderRadius="lg"
                       p={3}
                >
                    <div className="path-container">
                        {currentPath.join(' > ')}
                        {currentPath.length > 1 && (
                            <MDButton variant={'contained'} style={{marginLeft: 10}} color={'info'} onClick={goBack}><Icon>arrow_back</Icon></MDButton> // Кнопка для возврата на уровень выше
                        )}
                    </div>
                </MDBox>
            </MDBox>

            <MDBox
                mx={2}
                mt={3}
                py={3}
                px={2}
            >
                <Treebeard
                    style={style}
                    data={treeData} // Для обновления данных дерева
                    decorators={CustomDecorators}
                    onToggle={handleToggle} // Устанавливаем обработчик
                />
            </MDBox>
        </>
    );
};


export default TreeView;


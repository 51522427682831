import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import {useDispatch} from "react-redux";
import Fade from "@mui/material/Fade";
import MDBox from "../MDBox";
import Grid from "@mui/material/Grid";
import MDTypography from "../MDTypography";
import MDInput from "../MDInput";
import MDButton from "../MDButton";
import {
    useCreateClientMutation,
    useCreateUserMutation,
    useUpdatePasswordMutation
} from "../../Redux/slices/userApiSlice";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import {useState} from "react";
import {errorToast, infoToast} from "../../utils/toastify";
import Modal from "@mui/material/Modal";
import {useCreateFeedbackMutation, useSendFeedbackMutation} from "../../Redux/slices/feedbackApiSlice";
import {FormControl, FormGroup, Input, TextareaAutosize} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";


export default function CreateFeedback() {

    const [createFeedback, {isLoading}] = useCreateFeedbackMutation()
    const [sendFeedback] = useSendFeedbackMutation()
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch()
    const [has, setHas] = useState(false)
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        borderRadius: '14px',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };
    const [email, setEmail] = useState('')

    const [title, setTitle] = useState('')
    const [content, setContent] = useState('')

    const submitHandler = async (e) => {

        e.preventDefault();
        try {

            const res = await createFeedback({title, email, content}).unwrap();
            const res1 = await sendFeedback()

            setTitle('')
            setEmail('')
            setContent('')
            infoToast(`Thank you for your feeback!`)
            setOpen(false)

        } catch (err) {
            errorToast(err.error);
        }
    };
    const handleClickOpen = () => {
        setOpen(true);
    };
    const onEmail = (e) => {
        let re = /["'<>/+=~`?,|№:!#$%^&*()]/;
        if(re.test(e.target.value)){
            setHas(true)
        } else{
            setEmail(e.target.value)
        }
    }
    const onTitle = (e) => {
        let re = /["'<>/+=_~`?,.|№:!@#$%^&*()]/;
        if(re.test(e.target.value)){
            setHas(true)
        } else{
            setTitle(e.target.value)
        }
    }

    const onContent = (e) => {
        let re = /["'<>/+=_~`|№:!@#$%^&*()]/;
        if(re.test(e.target.value)){
            setHas(true)
        } else{
            setContent(e.target.value)
        }
    }


    const handleClose = () => {
        setOpen(false);
    };


    return (
        <React.Fragment>
                <Button variant={'outlined'} sx={{color: 'white', marginLeft: '40%'}} onClick={handleClickOpen}>Feedback</Button>
            <Modal
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition

            >
                <Fade in={open}>
                    <Box sx={style}>
                        <form onSubmit={submitHandler}>
                            <FormGroup>
                                <FormControl>
                                    <label >Email address</label>
                                    <TextField required={true} type={'email'} value={email} onChange={onEmail} id="my-input" aria-describedby="my-helper-text" />
                                </FormControl>
                                <FormControl>
                                    <label >Title</label>
                                    <TextField required={true} value={title} onChange={onTitle} id="my-input3" aria-describedby="my-helper-text" />
                                </FormControl>
                                <FormControl>
                                    <label >Content</label>
                                    <TextareaAutosize required={true} minRows={4} value={content} onChange={onContent} id="message" aria-describedby="my-helper-text" />
                                </FormControl>
                                <Button type={'submit'} sx={{marginTop:'5%'}} variant={'contained'}>
                                    {isLoading ? "Loading..." : "Submit"}
                                </Button>
                            </FormGroup>
                        </form>
                    </Box>
                </Fade>
            </Modal>
        </React.Fragment>
    );
}

import { apiSlice } from './apiSlice';
import { io } from 'socket.io-client';
import {infoToast} from "../../utils/toastify";
import store from "../../store";
import {current} from "@reduxjs/toolkit";

const socket = io(process.env.REACT_APP_BASE_URL, {withCredentials: false});
export const messageApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getMessages: builder.query({
            query: ({id, userId}) => ({
                url: `/messages/getMessages/`,
                params: {
                    id,
                    userId
                }
            }),

            async onCacheEntryAdded(
                arg,

                {  updateCachedData, cacheDataLoaded, cacheEntryRemoved },
            ) {
                try {
                    await cacheDataLoaded;
                    // the /chat-messages endpoint responded already


                    socket.on("RECEIVE_MESSAGE", (message) => {
                        infoToast(`New Message from ${message.title}`)
                        updateCachedData((draft) => {
                            draft.data = JSON.parse(JSON.stringify(message.messy))
                        });
                    });
                    socket.on("RECEIVE_MESSAGE1", (message) => {
                        updateCachedData((draft) => {
                            draft.data = JSON.parse(JSON.stringify(message))
                        });
                    });
                    socket.on("READ_SEND", (message) => {
                        updateCachedData((draft) => {
                            draft.data = JSON.parse(JSON.stringify(message))

                        });
                    });

                    await cacheEntryRemoved;
                    socket.off('connect');

                } catch {

                }
            },
            providesTags: ['Message'],
        }),



        sendMessage: builder.mutation({
            query: (message) => {
                socket.emit("SEND_MESSAGE", message)
            },
            invalidatesTags: ['Message'],
        }),
        deleteMessage: builder.mutation({
            query: (message) => {
                socket.emit("DELETE_MESSAGE", message)
            },
            invalidatesTags: ['Message'],
        }),
        joinChat: builder.mutation({
            query: (roomId) => {
                socket.emit("JOIN_ROOM", roomId)
            },
            invalidatesTags: ['Online']
        }),
        userOnline: builder.mutation({
            query: (roomId) => {
                socket.emit("USER_ONLINE", roomId)
            },
            invalidatesTags: ['Online']

        }),
        userOffline: builder.mutation({
            query: (roomId) => {
                socket.emit("USER_OFFLINE", roomId)
            },
            invalidatesTags: ['Online']

        }),
        setRead: builder.mutation({
            query: (roomId) => {
                socket.emit("JOIN_ROOM", roomId)
            },

        }),

        updateRead: builder.mutation({
            query: (data) => {
                socket.emit("UPDATE_READ", data)
            },

        }),
        sandy: builder.mutation({
            query: (message) => {
                socket.emit("GETTY", message)
            },
            invalidatesTags: ['Message'],
        }),



    }),
});




export const {
    useGetMessagesQuery,
    useUserOfflineMutation,
    useUpdateReadMutation,
    useUserOnlineMutation,
    useJoinChatMutation,
    useDeleteMessageMutation,
    useSendMessageMutation,
    useSandyMutation
} = messageApiSlice;
/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import clientTableData from "./data/clientTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import {useEffect, useState} from "react";
import CreateClient from "../../components/Modals/CreateClient";
import Tooltip from "@mui/material/Tooltip";
import MDButton from "../../components/MDButton";
import Icon from "@mui/material/Icon";
import UpdatePassword from "../../components/Modals/UpdatePassword";
import {useDeleteUserMutation, useGetClientsQuery, useGetOnlineQuery} from "../../Redux/slices/userApiSlice";
import {errorToast, infoToast} from "../../utils/toastify";
import MDBadge from "../../components/MDBadge";
import {formatter} from "../../utils/fornatter";

function Client() {
    const [open, setOpen] = useState(false)
    const {data: clients} = useGetClientsQuery()
    const [deleteUser, {isLoading}] = useDeleteUserMutation()
    const { columns } = clientTableData();
    const [rows, setRows] = useState([])
    const {data: onlines} = useGetOnlineQuery()


    const onDelete = async (id) => {
        if (confirm("Are you sure?")){
            try {
                const res = await deleteUser(id)
                infoToast("Success")
            } catch (err){
                errorToast(err.error)
            }
        }
    }

    useEffect(() => {
        let arr = []
        if (clients && onlines){
            arr = clients.data.map(item => {
                return  {
                    id: <MDTypography>{item.id}</MDTypography>,
                    username: (
                        <MDTypography>{item.username}</MDTypography>
                    ),
                    password: (
                        <MDTypography>{item.password}</MDTypography>
                    ),
                    online: (
                        <MDBox ml={-1}>
                            <MDBadge badgeContent={onlines.data.includes(item.id) ? "online" : "offline"} color={onlines.data.includes(item.id) ? "success" : "error"} variant="gradient" size="sm" />
                        </MDBox>
                    ),

                    date: (
                        <MDTypography>{formatter.format(Date.parse(item.createdAt))}</MDTypography>
                    ),
                    action: (
                        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                            <Tooltip title={'Delete'}>
                                <MDButton color={'error'} onClick={() => onDelete(item.id)} style={{marginRight: 15}} variant={'contained'}><Icon>delete</Icon></MDButton>
                            </Tooltip>
                            <UpdatePassword userId={item.id}/>
                        </MDTypography>
                    ),


                }
            })
        }
        setRows(arr)
    }, [clients, onlines])

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <Grid container>
                                    <Grid item xs={10}>
                                        <MDTypography variant="h6" color="white">
                                            Clients
                                        </MDTypography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <CreateClient/>
                                    </Grid>
                                </Grid>
                            </MDBox>
                            <MDBox pt={3}>
                                {clients ? (
                                    <DataTable
                                        table={{ columns, rows }}
                                        isSorted={false}
                                        entriesPerPage={false}
                                        showTotalEntries={false}
                                        noEndBorder
                                    />
                                ) : "loading"}
                            </MDBox>
                        </Card>
                    </Grid>

                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default Client;

/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import {Treebeard} from 'react-treebeard';


// Data
import {useEffect, useState} from "react";
import newclientTableData from "../newClient/data/newclientTableData";
import {useGetNewClientsQuery} from "../../Redux/slices/newClientApiSlice";
import {useGetPostByIdQuery, useGetPostsAvailable, useGetPostsAvailableQuery} from "../../Redux/slices/postApiSlice";
import Tooltip from "@mui/material/Tooltip";
import MDButton from "../../components/MDButton";
import Icon from "@mui/material/Icon";
import {useCreateChatMutation} from "../../Redux/slices/chatApiSlice";
import {useNavigation, useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {errorToast, infoToast} from "../../utils/toastify";
import TargetInfo from "../../components/Modals/TargetInfo";
import InfoUser from "../../components/Modals/InfoUser";
import {Link, useNavigate} from "react-router-dom";
import TreeView, {TreeExample} from "./TreeView";
import {useSendBuyMutation} from "../../Redux/slices/buyedApiSlice";
import {setCart} from "../../Redux/slices/cartSlice";

function FileTree() {
    const [createChat] = useCreateChatMutation()
    const dispatch = useDispatch()


    const {id} = useParams()
    const {data: post, isLoading} = useGetPostByIdQuery(id)
    const user = useSelector(state => state.auth.userInfo.user)
    const cart = useSelector(state => state.cart.cart)
    const [sendBuy] = useSendBuyMutation()
    const navigate = useNavigate()


    const submitHandler = async (e) => {
        e.preventDefault()
        await sendBuy({files: cart.files, username: user.username, price: cart.totalPrice, postName: post.data.title})
        await createChat({userId: user.id, target: post.data.title + ' Files', title: user.username + ' files', partnerId: post.data.partnerId }).unwrap()
        dispatch(setCart({...cart, totalPrice: 0}))
        infoToast("We will contact you in chat section")

        navigate('/client/clientBuy')
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <form onSubmit={submitHandler}>
                            <Card>

                                <MDBox pt={3}>
                                    {post ? <TreeView dataa={post.data.computers}/>: null}
                                </MDBox>
                                <MDButton type={'submit'} disabled={user.balance < cart.totalPrice || cart.totalPrice === 0 ? true : false} variant={'contained'} color={'info'}>{cart.totalPrice === 0 ? "Select some files" : user.balance < cart.totalPrice ? "You don't have money on your balance" : `Buy for ${cart.totalPrice}$`}</MDButton>

                            </Card>
                        </form>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default FileTree;

import React, {useCallback, useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import ResponsiveAppBar from "../../components/navbar";
import img from '../../assets/images/bg-profile.jpeg'
import BuyModal from "./modal";
import {useGetPostByIdQuery} from "../../Redux/slices/postApiSlice";
import MyTimer from "../../components/Timer";
import {useParams} from "react-router";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, EffectFade } from 'swiper';
import ImageViewer from "react-simple-image-viewer";
import {useAddVisitMutation} from "../../Redux/slices/chatApiSlice";
import InfoPost from "../../components/Modals/InfoPost";
import {Link} from "react-router-dom";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";
export default function ProductPage() {
    const {id} = useParams()
    const {data: post, isLoading} = useGetPostByIdQuery(id)
    const [addVisit] = useAddVisitMutation()
    const [timed, setTimed] = useState(false)
    useEffect(() => {
        if (post){
            const created = new Date(post.data.timer)

            const rightNow = new Date()
            if (rightNow.getTime() > created.getTime()){
                setTimed(true)
            } else{
                setTimed(false)

            }
        }
    }, [post])
    const createMarkup = (some)  => {
        return {__html: some};
    }
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const images = [
        "http://placeimg.com/1200/800/nature",
        "http://placeimg.com/800/1200/nature",
        "http://placeimg.com/1920/1080/nature",
        "http://placeimg.com/1500/500/nature"
    ];
    useEffect(() => {
        const res = addVisit(id)
    }, [])

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);
    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    return (
        <>


            <Box
                variant="contained"
                bgColor="transparent"
                color="dark"
                opacity={1}
                borderRadius="none"
                shadow="none"
                coloredShadow="none"
                px={30}
                style={{margin: '0 auto'}}>



                <div className="row mt-5">
                    <ResponsiveAppBar/>
                    <div className="row" style={{marginTop: 50}}>
                        <div className="col-12">
                            <div className="card">
                                {isLoading ? null : (
                                    <div className="card-body">
                                        <Link to={'/'}>
                                            <Icon>reply </Icon>
                                        </Link>
                                        <div className="row">
                                            <div className={'col-xl-5 col-lg-6 text-center'}>
                                                <img className="w-100 border-radius-lg shadow-lg mx-auto"
                                                     src={`${process.env.REACT_APP_BASE_URL}${post.data.images[0]}`}
                                                     alt="chair"
                                                     onClick={() => openImageViewer(0)}
                                                />

                                                <Swiper
                                                    modules={[Navigation, Pagination, Scrollbar, A11y, EffectFade]}
                                                    direction={'horizontal'}
                                                    spaceBetween={0}
                                                    slidesPerView={1}

                                                    navigation
                                                    pagination={{ clickable: true }}
                                                    scrollbar={{ draggable: true }}
                                                    onSwiper={(swiper) => console.log(swiper)}
                                                    onSlideChange={() => console.log('slide change')}
                                                >
                                                    <div  className="my-gallery mt-4" style={{}} >
                                                        {post.data.images ?post.data.images.map((item, index) => {
                                                            return (

                                                                    <figure itemProp="associatedMedia" itemScope
                                                                            style={{margin: '10px', display: 'inline', maxWidth: "100%"}}

                                                                            itemType="http://schema.org/ImageObject" onClick={() => openImageViewer(index)}>

                                                                        <img
                                                                            className=" min-height-100 max-height-100 border-radius-lg shadow"
                                                                            style={{margin: '10px', display: 'inline', overflow: 'hidden',  maxWidth: "100%"}}
                                                                            src={`${process.env.REACT_APP_BASE_URL}${item}`}
                                                                            itemProp="thumbnail" alt="Image description"/>
                                                                    </figure>


                                                            )
                                                        }) : "loading"}
                                                    </div>

                                                </Swiper>
                                                {isViewerOpen && (
                                                    <ImageViewer
                                                        src={post.data.images.map(item => {
                                                            return `${process.env.REACT_APP_BASE_URL}${item}`
                                                        })}
                                                        currentIndex={currentImage}
                                                        onClose={closeImageViewer}
                                                        disableScroll={false}
                                                        backgroundStyle={{
                                                            backgroundColor: "rgba(0,0,0,0.9)"
                                                        }}
                                                        closeOnClickOutside={true}
                                                    />
                                                )}
                                            </div>

                                            <div className="col-lg-5 mx-auto">
                                                <h3 className="mt-lg-0 mt-4">{post.data.title}</h3>

                                                <br/>
                                                {post.data.isSell ? (
                                                    <Grid container>
                                                        <Grid item xs={6}>
                                                            <h6  className="">Price in one hands</h6>
                                                            <h5 style={{textDecoration: post.data.onediscount ? 'line-through #ea3201 2px' : 'none'}}>{post.data.oneprice}$</h5>
                                                            <h6 className="">Price in several hands</h6>
                                                            <h5 style={{textDecoration: post.data.discount ? 'line-through #ea3201 2px' : 'none'}}>{post.data.price}$</h5>
                                                        </Grid>
                                                        {post.data.discount && post.data.onediscount ? (
                                                            <Grid item xs={6}>
                                                                <h6 className="">Discount</h6>
                                                                <h5>{post.data.discount}$</h5>
                                                                <h6 className="">Discount</h6>
                                                                <h5>{post.data.onediscount}$</h5>
                                                            </Grid>
                                                        ) : null}
                                                    </Grid>
                                                ) : timed ? <InfoPost links={post.data.links}/> : (
                                                    <MyTimer expiryTimestamp={Date.parse(post.data.timer)}/>
                                                )}

                                                <br/>
                                                <label className="mt-4">Description</label>
                                                <p dangerouslySetInnerHTML={{__html: post.data.description}}></p>

                                                {post.data.isSell ? (
                                                    <div className="row mt-4">
                                                        <div className="col-lg-5">
                                                            <BuyModal/>
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>

                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                </div>
            </Box>

        </>
    );
};


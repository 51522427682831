import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import {useDispatch, useSelector} from "react-redux";
import Fade from "@mui/material/Fade";
import MDBox from "../MDBox";
import Grid from "@mui/material/Grid";
import MDTypography from "../MDTypography";
import MDInput from "../MDInput";
import MDButton from "../MDButton";
import {
    useCreateClientMutation, useCreatePartnerMutation,
    useCreateUserMutation,
    useUpdatePasswordMutation
} from "../../Redux/slices/userApiSlice";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import {useState} from "react";
import {errorToast, infoToast} from "../../utils/toastify";
import Modal from "@mui/material/Modal";
import {InputAdornment} from "@mui/material";
import {useEditTargetMutation} from "../../Redux/slices/targetApiSlice";


export default function InfoUser({item}) {
    const user = useSelector(state => state.auth.userInfo.user)

    const [title, setTitle] = useState(item.title)
    const [price, setPrice] = useState(item.price)
    const [description, setDescription] = useState(item.description)
    const [zoom, setZoom] = useState(item.zoomInfo)
    const [reason, setReason] = useState(item.reason)
    const [link, setLink] = useState(item.link)
    const [site, setSite] = useState(item.site)

    const [has, setHas] = useState(false)



    const onTitle = (e) => {
        let re = /["'<>/+=~`?,|№:!#$%^&*()]/;
        if(re.test(e.target.value)){
            setHas(true)
        } else{
            setTitle(e.target.value)
        }
    }
    const onDescription = (e) => {
        let re = /["'<>/+=~`?,|№!#$%^&*()]/;
        if(re.test(e.target.value)){
            setHas(true)
        } else{
            setDescription(e.target.value)
        }
    }
    const onZoom = (e) => {
        let re = /["'<>+=~`?,|№!#$%^&*()]/;
        if(re.test(e.target.value)){
            setHas(true)
        } else{
            setZoom(e.target.value)
        }
    }
    const onReason = (e) => {
        let re = /["'<>+=~`./?,|№!#$%^&*()]/;
        if(re.test(e.target.value)){
            setHas(true)
        } else{
            setReason(e.target.value)
        }
    }
    const onLink = (e) => {
        let re = /[<>]/;
        if(re.test(e.target.value)){
            setHas(true)
        } else{
            setLink(e.target.value)
        }
    }
    const onSite = (e) => {
        let re = /["'<>+=~`?,|№!#$%^&*()]/;
        if(re.test(e.target.value)){
            setHas(true)
        } else{
            setSite(e.target.value)
        }
    }






    const [open, setOpen] = useState(false);
    const dispatch = useDispatch()
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        borderRadius: '14px',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };
    const [username, setUsername] = useState('')
    const onCopy = () => {
        navigator.clipboard.writeText(link);

    }

    const [password, setPassword] = useState('')
    const submitHandler =  (e) => {

        e.preventDefault();
        setOpen(false)
    };
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <React.Fragment>
            <Tooltip title={'Info'}>
                <MDButton color={'info'} style={{marginRight: 15}} onClick={handleClickOpen}><Icon>info</Icon></MDButton>
            </Tooltip>
            <Modal
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition

            >
                <Fade in={open}>
                    <MDBox sx={style}>
                        <form onSubmit={submitHandler}>
                            <Grid mb={5} container>

                                <Grid container direction="row">
                                    <Grid item xs={8}>
                                        <MDTypography variant="h5">
                                            Target Info
                                        </MDTypography>
                                    </Grid>

                                </Grid>
                                <Grid
                                    container
                                    direction="row"

                                    spacing={3}
                                >
                                    <Grid item mt={3} xs={12}>
                                        <MDInput
                                            value={title}
                                            onChange={onTitle}
                                            fullWidth
                                            margin="dense"
                                            type={'text'}
                                            disabled={true}
                                            variant="outlined"
                                            label="Title"
                                            id="Title"
                                        />
                                    </Grid>


                                    <Grid item mt={1} xs={12}>
                                        <MDInput
                                            value={price}
                                            onChange={(e) => setPrice(e.target.value)}
                                            fullWidth
                                            disabled={true}
                                            margin="dense"
                                            type={'number'}
                                            variant="outlined"
                                            label="Price"
                                            id="Price"
                                            required
                                        />
                                    </Grid>
                                    <Grid item mt={1} xs={12}>
                                        <MDInput
                                            value={item.oneprice}
                                            onChange={(e) => setPrice(e.target.value)}
                                            fullWidth
                                            disabled={true}
                                            margin="dense"
                                            type={'number'}
                                            variant="outlined"
                                            label="Price in one hands"
                                            id="Price"
                                            required
                                        />
                                    </Grid>
                                    <Grid style={{margin: '10%'}}>
                                        <label className="mt-4">Description</label>
                                        <p dangerouslySetInnerHTML={{__html: item.description}}></p>
                                    </Grid>

                                </Grid>


                            </Grid>
                            <MDButton type={'submit'} color={'info'} style={{marginTop: 4}}>{"Close"}</MDButton>

                        </form>
                    </MDBox>
                </Fade>
            </Modal>
        </React.Fragment>
    );
}
import React, {useEffect, useState} from 'react';
import List from "@mui/material/List";
import Contact from "./contact";
import {useGetChatsByIdQuery} from "../../../Redux/slices/chatApiSlice";
import {useDispatch, useSelector} from "react-redux";
import {setChat} from "../../../Redux/slices/chatSlice";
import {useGetMessagesQuery} from "../../../Redux/slices/messageApiSlice";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Icon from "@mui/material/Icon";
const ContactList = () => {
    const user = useSelector(state => state.auth.userInfo.user)
    const dispatch = useDispatch()
    const [search, setSearch] = useState('')
    const chat = useSelector(state => state.chats.chatInfo)

    const [type, setType] = React.useState('');

    const handleChange = (event, newValue) => {
        setType(newValue);


    };


    const {data: chats, isLoading} = useGetChatsByIdQuery({id:user.id, type})
    const [sorted, setSorted] = useState(chats ? chats.data : [])


    useEffect(() => {

        if (chats && search) {
            const filteredItems = chats.data.filter((item) =>
                item.target.toLowerCase().includes(search.toLowerCase())
            );

            setSorted(filteredItems);
        }
        if (chats && !search) {

            setSorted(chats.data);
        }
    }, [search, type, chats])



    return (
        <>
            {user.isAdmin ? (
                <Tabs
                    value={type}
                    onChange={handleChange}
                    textColor="secondary"
                    indicatorColor="secondary"
                    aria-label="secondary tabs example"
                    style={{margin: 15}}

                >
                    <Tab  icon={<Icon>attach_money</Icon>} iconPosition="start" value="support" label="Support" />
                    <Tab icon={<Icon>help_outline</Icon>} iconPosition="start" value="money" label="Clients" />
                </Tabs>
            ) : null}
            <Divider/>

            <TextField
                value={search}
                label={'Find chat'}
                onChange={e => setSearch(e.target.value)}
            style={{marginLeft: 20}}
            />
            <Divider/>
            <List style={{minHeight: '700px'}}>

                {chats ?  (
                    sorted.map(item => {
                        return <Contact  chat={item}/>
                    })
                ) : null}
            </List>
        </>
    );
};

export default ContactList;
import { apiSlice } from './apiSlice';

export const chatApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getChatsById: builder.query({
            query: (data) => ({
                url: `/chat/getChats/?id=${data.id || ''}&type=${data.type || ''}`,
            }),
            keepUnusedDataFor: 5,
            providesTags: ['Chat'],
        }),

        deleteChat: builder.mutation({
            query: (id) => ({
                url: `/chat/deleteChat/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Chat'],
        }),
        declineChat: builder.mutation({
            query: (id) => ({
                url: `/chat/declineChat/${id}`,
                method: 'PUT',

            }),
            invalidatesTags: ['Chat'],
        }),
        approveChat: builder.mutation({
            query: (id) => ({
                url: `/chat/approveChat/${id}`,
                method: 'PUT',

            }),
            invalidatesTags: ['Chat'],
        }),
        createChat: builder.mutation({
            query: (data) => ({
                url: `/chat/createChat`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['Chat'],
        }),
        createPartnerChat: builder.mutation({
            query: (data) => ({
                url: `/chat/createPartnerChat`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['Chat'],
        }),
        addVisit: builder.mutation({
            query: (id) => ({
                url: `/chat/visit/${id}`,
                method: 'PUT',

            }),
            invalidatesTags: ['Chat'],
        }),


    }),
});

export const {
    useGetChatsByIdQuery,
    useCreateChatMutation,
    useCreatePartnerChatMutation,
    useDeclineChatMutation,
    useApproveChatMutation,
    useDeleteChatMutation,
    useAddVisitMutation
} = chatApiSlice;
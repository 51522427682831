/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
 =========================================================
 * Material Dashboard 2 React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import DeleteIcon from '@mui/icons-material/Delete';
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import MDButton from "../../../components/MDButton";
import Tooltip from "@mui/material/Tooltip";

export default function data() {
    const Author = ({ image, name, email }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>

            <MDBox ml={2} lineHeight={1}>
                <MDTypography display="block" variant="button" fontWeight="medium">
                    {email}
                </MDTypography>
            </MDBox>
        </MDBox>
    );

    const Job = ({ title, description }) => (
        <MDBox lineHeight={1} textAlign="left">
            <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                {title}
            </MDTypography>
            <MDTypography variant="caption">{description}</MDTypography>
        </MDBox>
    );

    return {
        columns: [
            { Header: "ID", accessor: "id", align: "left" },
            { Header: "Username", accessor: "username", align: "left" },
            { Header: "Status", accessor: "online", align: "left" },
            { Header: "IsBlocked", accessor: "block", align: "left" },
            { Header: "action", accessor: "action", align: "center" },
        ],

        rows: [
            {
                id: <MDTypography>1</MDTypography>,
                username: <Author image={team3} name="Alexa Liras" email="alexa@gmail.com" />,
                password: (
                    <MDTypography>ewewweweew</MDTypography>
                ),

                action: (
                    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                        <Tooltip title={'Delete'}>
                            <MDButton color={'error'} style={{marginRight: 15}} variant={'contained'}><Icon>delete</Icon></MDButton>
                        </Tooltip>
                        <Tooltip title={'Edit'}>
                            <MDButton color={'warning'} style={{marginRight: 15}} variant={'contained'}><Icon>edit</Icon></MDButton>
                        </Tooltip>
                    </MDTypography>
                ),
            },
            {
                id: <MDTypography>1</MDTypography>,
                username: <Author image={team3} name="Alexa Liras" email="alexa@gmail.com" />,
                password: (
                    <MDTypography>ewewweweew</MDTypography>
                ),

                action: (
                    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                        <Tooltip title={'Delete'}>
                            <MDButton color={'error'} style={{marginRight: 15}} variant={'contained'}><Icon>delete</Icon></MDButton>
                        </Tooltip>
                        <Tooltip title={'Edit'}>
                            <MDButton color={'warning'} style={{marginRight: 15}} variant={'contained'}><Icon>edit</Icon></MDButton>
                        </Tooltip>
                    </MDTypography>
                ),
            },
            {
                id: <MDTypography>1</MDTypography>,
                username: <Author image={team3} name="Alexa Liras" email="alexa@gmail.com" />,
                password: (
                    <MDTypography>ewewweweew</MDTypography>
                ),

                action: (
                    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                        <Tooltip title={'Delete'}>
                            <MDButton color={'error'} style={{marginRight: 15}} variant={'contained'}><Icon>delete</Icon></MDButton>
                        </Tooltip>
                        <Tooltip title={'Edit'}>
                            <MDButton color={'warning'} style={{marginRight: 15}} variant={'contained'}><Icon>edit</Icon></MDButton>
                        </Tooltip>
                    </MDTypography>
                ),
            },
            {
                id: <MDTypography>1</MDTypography>,
                username: <Author image={team3} name="Alexa Liras" email="alexa@gmail.com" />,
                password: (
                    <MDTypography>ewewweweew</MDTypography>
                ),

                action: (
                    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                        <Tooltip title={'Delete'}>
                            <MDButton color={'error'} style={{marginRight: 15}} variant={'contained'}><Icon>delete</Icon></MDButton>
                        </Tooltip>
                        <Tooltip title={'Edit'}>
                            <MDButton color={'warning'} style={{marginRight: 15}} variant={'contained'}><Icon>edit</Icon></MDButton>
                        </Tooltip>
                    </MDTypography>
                ),
            },
            {
                id: <MDTypography>1</MDTypography>,
                username: <Author image={team3} name="Alexa Liras" email="alexa@gmail.com" />,
                password: (
                    <MDTypography>ewewweweew</MDTypography>
                ),

                action: (
                    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                        <Tooltip title={'Delete'}>
                            <MDButton color={'error'} style={{marginRight: 15}} variant={'contained'}><Icon>delete</Icon></MDButton>
                        </Tooltip>
                        <Tooltip title={'Edit'}>
                            <MDButton color={'warning'} style={{marginRight: 15}} variant={'contained'}><Icon>edit</Icon></MDButton>
                        </Tooltip>
                    </MDTypography>
                ),
            },
            {
                id: <MDTypography>1</MDTypography>,
                username: <Author image={team3} name="Alexa Liras" email="alexa@gmail.com" />,
                password: (
                    <MDTypography>ewewweweew</MDTypography>
                ),

                action: (
                    <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                        <Tooltip title={'Delete'}>
                            <MDButton color={'error'} style={{marginRight: 15}} variant={'contained'}><Icon>delete</Icon></MDButton>
                        </Tooltip>
                        <Tooltip title={'Edit'}>
                            <MDButton color={'warning'} style={{marginRight: 15}} variant={'contained'}><Icon>edit</Icon></MDButton>
                        </Tooltip>
                    </MDTypography>
                ),
            },
            // {
            //     author: <Author image={team3} name="Alexa Liras" email="alexa@creative-tim.com" />,
            //     function: <Job title="Programator" description="Developer" />,
            //     status: (
            //         <MDBox ml={-1}>
            //             <MDBadge badgeContent="offline" color="dark" variant="gradient" size="sm" />
            //         </MDBox>
            //     ),
            //     employed: (
            //         <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            //             11/01/19
            //         </MDTypography>
            //     ),
            //     action: (
            //         <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            //             Edit
            //         </MDTypography>
            //     ),
            // },
            // {
            //     author: <Author image={team4} name="Laurent Perrier" email="laurent@creative-tim.com" />,
            //     function: <Job title="Executive" description="Projects" />,
            //     status: (
            //         <MDBox ml={-1}>
            //             <MDBadge badgeContent="online" color="success" variant="gradient" size="sm" />
            //         </MDBox>
            //     ),
            //     employed: (
            //         <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            //             19/09/17
            //         </MDTypography>
            //     ),
            //     action: (
            //         <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            //             Edit
            //         </MDTypography>
            //     ),
            // },
            // {
            //     author: <Author image={team3} name="Michael Levi" email="michael@creative-tim.com" />,
            //     function: <Job title="Programator" description="Developer" />,
            //     status: (
            //         <MDBox ml={-1}>
            //             <MDBadge badgeContent="online" color="success" variant="gradient" size="sm" />
            //         </MDBox>
            //     ),
            //     employed: (
            //         <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            //             24/12/08
            //         </MDTypography>
            //     ),
            //     action: (
            //         <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            //             Edit
            //         </MDTypography>
            //     ),
            // },
            // {
            //     author: <Author image={team3} name="Richard Gran" email="richard@creative-tim.com" />,
            //     function: <Job title="Manager" description="Executive" />,
            //     status: (
            //         <MDBox ml={-1}>
            //             <MDBadge badgeContent="offline" color="dark" variant="gradient" size="sm" />
            //         </MDBox>
            //     ),
            //     employed: (
            //         <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            //             04/10/21
            //         </MDTypography>
            //     ),
            //     action: (
            //         <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            //             Edit
            //         </MDTypography>
            //     ),
            // },
            // {
            //     author: <Author image={team4} name="Miriam Eric" email="miriam@creative-tim.com" />,
            //     function: <Job title="Programator" description="Developer" />,
            //     status: (
            //         <MDBox ml={-1}>
            //             <MDBadge badgeContent="offline" color="dark" variant="gradient" size="sm" />
            //         </MDBox>
            //     ),
            //     employed: (
            //         <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            //             14/09/20
            //         </MDTypography>
            //     ),
            //     action: (
            //         <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            //             Edit
            //         </MDTypography>
            //     ),
            // },
        ],
    };
}
